import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import logo from '../images/logo.jpeg';
import colage from '../images/torti_site_collage.png'
import Testimonials from './Testimonials';



const Home = () => {
  const text = (
    <>
      <p>
        The locally and family-owned Tortilleria Reyes has been in business for 20 years. In August of 2021, Jesus Reyes bought the place from his mother, Martha.
      </p>
      <p>
        At the Tortilleria, you can expect to find delicious tacos made with freshly-made tortillas, along with other authentic made-from-scratch Mexican dishes.
      </p>
      <p>
        Meats include:
      </p>
      <ul className='no-bullets'l>
        <li>Barbacoa</li>
        <li>Adobada</li>
        <li>Asada</li>
        <li>Pollo</li>
        <li>Chorizo</li>
        <li>Birria (goat)</li>
        <li>Michoacán style carnitas with buche, pata, Costillas and cuerito</li>
      </ul>
    </>
  );
  
  const condition = true;
  
  return (
    <div className="home">
      <img className='colage' src={colage} alt="Tortilleria Reyes Colage" />
      <img className='home-logo' src={logo} alt="Tortilleria Reyes Logo" />
      <div className={`content ${condition ? 'center-description' : ''}`}>
        <div className={`description ${condition ? 'centered' : ''}`}>
          <h2>Restaurant, Market, and Tortilleria</h2>
          <p>{text}</p>
          <div className="buttons-container">
            <a href="https://www.clover.com/online-ordering/tortilleria-reyes-bend" className="order-button" target="_blank" rel="noopener noreferrer">Order Now</a>
            <Link to="/about" className="order-button">Learn More</Link>
          </div>
        </div>
      </div>
      <Testimonials />
    </div>
  )
}

export default Home;