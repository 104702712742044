import React, { useState } from 'react';
import './PersonCard.css';

const PersonCard = ({ image, name, description }) => {
  const [showDescription, setShowDescription] = useState(false);

  return (
    <div className="person-card">
      <div className="person-image" style={{ backgroundImage: `url(${image})` }} onClick={() => setShowDescription(!showDescription)}></div>
      <h3>{name}</h3> 
      {showDescription && (
        <div className="person-description">
          <p>{description}</p>
        </div>
      )}
    </div>
  );
};

export default PersonCard;
