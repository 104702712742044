import React from 'react';
import './Button.css';

const Button = ({ children, onClick, color}) => {
  const buttonStyle = {
    backgroundColor: color
  }



  return (
    <button className='button' onClick={onClick} style={buttonStyle}>
      {children}
    </button>
  );
}

export default Button;