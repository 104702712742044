import React from 'react';
import PersonCard from './PersonCard';
import person1 from '../images/torta.jpg';
import person2 from '../images/enchiladas.jpg';
import './About.css'

const About = () => {
  return (
    <div>
      <h2>Click on each photo to learn about the Tortilleria Reyes team!</h2>
      <div className="about">
        <PersonCard image={person1} name="Martha Reyes" description="Martha is the founder of the Tortilleria. You can see her working in the kitchen from time to time." />
        <PersonCard image={person2} name="Jesus Reyes" description="Jesus is the son of founder Martha and is responsible for everything that goes on in the Tortilleria." />
        
      </div>
    </div>
  );
};

export default About;
