import React from 'react';
import './Contact.css';
import Button from './Button';

const Contact = () => {
  return (
    <div className="contact-form">
      <h1>Contact Us</h1>
      <form action="https://formspree.io/f/mvojgaar" method="POST">
        <label>
          Name:
          <input type="text" name="name" />
        </label>
        <label>
          Email:
          <input type="text" name="email" />
        </label>
        <label>
          Message:
          <textarea name="message"></textarea>
        </label>
        <Button type="submit"> Submit </Button>
      </form>
    </div>
  );
}

export default Contact;
