import React, { useState } from 'react';
import './Catering.css';
import Button from './Button';

const CateringCalculator = () => {
  const [carnitas, setCarnitas] = useState(0);
  const [carneAsada, setCarneAsada] = useState(0);
  const [barbacoa, setBarbacoa] = useState(0);
  const [tortillas, setTortillas] = useState(0);

  const prices = {
    carnitas: 13.85, 
    carneAsada: 15.46, 
    barbacoa: 15.46, 
    tortillas: 3.08
  };

  const handleInputChange = (e, setterFunc) => {
    const val = parseFloat(e.target.value);
    if (val < 0) return;
    setterFunc(val);
  };

  const calculateTotal = () => {
    const total =
      carnitas * prices.carnitas +
      carneAsada * prices.carneAsada +
      barbacoa * prices.barbacoa +
      tortillas * prices.tortillas;

    return total.toFixed(2);
  };

  const handleCall = () => {
    window.location.href = "tel:+15413832025";
  };

  const handleEmail = () => {
    window.location.href = "mailto:info@tortilleriareyes.com"
  }

  return (
    <div>
      <div className='header-text'>
        <h1>The Tortilleria Reyes also does catering!</h1>
        <p>We can provide food for any type of event. Whether it's a wedding, birthday party, or anything in between.</p>
      <h2>Use the calculator below to get an estimate for your event.</h2>
      </div>
      <div className="calculator">
        <h1>Catering Calculator</h1>
        <div>
          <label>Carnitas (lbs): </label>
          <input type="number" min="0" value={carnitas} onChange={(e) => handleInputChange(e, setCarnitas)} />
        </div>
        <div>
          <label>Carne Asada (lbs): </label>
          <input type="number" min="0" value={carneAsada} onChange={(e) => handleInputChange(e, setCarneAsada)} />
        </div>
        <div>
          <label>Barbacoa (lbs): </label>
          <input type="number" min="0" value={barbacoa} onChange={(e) => handleInputChange(e, setBarbacoa)} />
        </div>
        <div>
          <label>Tortillas (lbs): </label>
          <input type="number" min="0" value={tortillas} onChange={(e) => handleInputChange(e, setTortillas)} />
        </div>
        <h2>Total: ${calculateTotal()}</h2>
      <Button onClick={handleCall}>Call Us</Button> 
      <Button onClick={handleEmail}>Email us</Button>
      </div>
    </div>
  );
};

export default CateringCalculator;
