import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagramSquare } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <div className="footer">
      <div className="location">
        <h3>Location</h3>
        <p>1155 SW Division Street</p>
        <p>Bend, OR 97702</p>
      </div>
      <div className="hours">
        <h3>Hours</h3>
        <p>Tuesday - Friday: 11am - 8pm</p>
        <p>Saturday & Sunday: 10am - 8pm</p>
      </div>
      <div className="contact">
        <h3>Contact</h3>
        <p>Phone: <a href="tel:+15413832025">(541) 383-2025</a></p>
        <p>Email: <a href="mailto:info@tortilleriareyes.com">info@tortilleriareyes.com</a></p>
      </div>
      <div className="socials">
        <a href="facebook.com/tortilleriareyesbend" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebookSquare} size='2x' />
        </a>
        <a href="https://www.instagram.com/tortilleriareyesbend/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagramSquare} size='2x' />
        </a>
      </div>
    </div>
  )
}

export default Footer;
