import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import logoMobile from "../images/logo.jpeg";
import logoDesktop from "../images/long_logo.png";
import "./Navbar.css";

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const closeMenu = () => {
    setMobileMenuOpen(false);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);  

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener("resize", handleResize);
    
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Remember to return your JSX
  return (
    <nav className="navbar-container">
      {windowWidth <= 768 ? (
        // Mobile navigation (hamburger menu)
        <div className="hamburger" onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        // Desktop navigation (horizontal links)
        <div className="nav-links-desktop">
          <NavLink to="/" exact className="nav-link-desktop">
            Home
          </NavLink>
          <NavLink to="/menu" className="nav-link-desktop">
            Menu
          </NavLink>
          <NavLink to="/about" className="nav-link-desktop">
            About
          </NavLink>
          <NavLink to="/contact" className="nav-link-desktop">
            Contact
          </NavLink>
          <NavLink to="/catering" className="nav-link-desktop">
            Catering
          </NavLink>
        </div>
      )}

      <div className="logo-container">
        <NavLink to="/" exact>
          <img src={logoMobile} alt="logo mobile" className="logo logo-mobile" />
          <img src={logoDesktop} alt="logo desktop" className="logo logo-desktop" />
        </NavLink>
      </div>
      <div className="navbar-order-container">
        <a href="https://www.clover.com/online-ordering/tortilleria-reyes-bend" className="order-button">
          Order Now
        </a>
      </div>
      {windowWidth <= 768 &&
        <div className={`nav-links ${isMobileMenuOpen ? "nav-open" : ""}`}>
          <div className="close-button" onClick={closeMenu}>
            X
          </div>
          <NavLink to="/" exact onClick={closeMenu} className="nav-link">
            Home
          </NavLink>
          <NavLink to="/menu" onClick={closeMenu} className="nav-link">
            Menu
          </NavLink>
          <NavLink to="/about" onClick={closeMenu} className="nav-link">
            About
          </NavLink>
          <NavLink to="/contact" onClick={closeMenu} className="nav-link">
            Contact
          </NavLink>
          <NavLink to="/catering" onClick={closeMenu} className="nav-link">
            Catering
          </NavLink>

        </div>
      }
    </nav>
  );
};

export default Navbar;
