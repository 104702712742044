import React from 'react';
import './Testimonials.css';
import googleLogo from '../images/google_logo.png'; 


const Testimonials = () => {
  const testimonialData = [
    {
      name: 'StrawberryBlite',
      text: 'Their tortillas, amazing! Their salsa, 10/10! Their Margaritas, fabulous! And their tacos? Best taco\'s ever!! I eat here way more than I should. But it\'s hard not to when everyone is so nice and the food is so good!',
      rating: 5,
    },
    {
      name: 'Barbara Thomas',
      text: 'Tortilleria Reyes catered my niece\'s graduation party. We ordered the chicken, carnitas and tortillas for the occasion.  They were a hit! So many compliments regarding the food.  All credit goes to the personable owner and employees of tortilleria Reyes.  Not only do they have great tasting authentic food but they were so helpful and friendly. I recommend Tortilleria Reyes for anyone who\'s looking for a good Mexican authentic meal with great service! Thank you, Tortilleria Reyes, for coming through and helping to make my niece\'s graduation party a hit!',
      rating: 5,
    },
    {
      name: 'Kylan Carson',
      text: 'This place is the secret to a great marriage, happy customers and hopefully the cure for cancer. Bertha and Jesus are two of the best people and cooks I\'ve met in a long time. They\'ve catered our wedding, made delicious food for important customers of mine and they helped me meal prep for chemotherapy. I honestly can\'t endorse this place enough.',
      rating: 5,
    },
    {
      name: 'Matthew Krunglevich',
      text: 'One of the most authentic and best little taco shops in bend. Excellent tacos, Chile relleno, and tamales all freshly prepared. Great portions of seasoned meat and fresh ingredients. Classic Mexican store atmosphere with a store in the back too. Service was okay. Prices were great. Very small and family friendly.',
      rating: 5,
    }
  ];

  const renderStars = (rating) => {
    let stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span key={i} className={i <= rating ? 'star filled' : 'star'}>
          ★
        </span>
      );
    }
    return stars;
  };

  return (
    <div className="testimonials">
      <h2>What Our Customers Say on</h2>
      <img className='google-logo' src={googleLogo} alt="Google Logo" />
      <div className="testimonial-container">
        {testimonialData.map((testimonial, index) => (
          <div className="testimonial" key={index}>
            <div className="rating">{renderStars(testimonial.rating)}</div>
            <h3>{testimonial.name}</h3>
            <p>{testimonial.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
