import React from 'react';
import menu1 from '../images/menu_1.jpg';
import menu2 from '../images/menu_2.jpg';
import menu3 from '../images/menu_3.jpg';
import menu4 from '../images/menu_4.jpg';
import { NavLink } from "react-router-dom";
import './Menu.css';

const Menu = () => {
  return (
    <div className="menu">
      <div className="food-description">
        <h1>Menu</h1>
      </div>
      <div className="menu-photos">
        <img src={menu1} alt="Menu 1" className="menu-photo"/>
        <img src={menu2} alt="Menu 2" className="menu-photo"/>
        <img src={menu3} alt="Menu 3" className="menu-photo"/>
        <img src={menu4} alt="Menu 4" className="menu-photo"/>
      </div>
      <div className="order-now">
        <NavLink to="https://www.clover.com/online-ordering/tortilleria-reyes-bend" className="nav-link-button">Order Now</NavLink>
      </div>
    </div>
  )
}

export default Menu;